import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import Content from './content'
import { navigate } from 'gatsby'
import titleImg from '../data/general-information/bg6.jpg'
import { isAdmin } from './auth/app-user'
import { API } from 'aws-amplify'
import { messagesByResolved }  from '../graphql/queries'
import { updateComm } from '../graphql/mutations'
import { onCreateComm, onUpdateComm } from '../graphql/subscriptions'
import { localDateTime } from '../utils/date-formatter'
import ReplyForm from './reply-form'

const MessageBrowser = () => {
  const [messages, setMessages] = useState([])
  const [triggerReply, setTriggerReply] = useState(false)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if (!isAdmin())
      navigate('/')
  }, [])

  const fetchUnresolvedMessages = async () => {
    try {
      const result = await API.graphql({
        query: messagesByResolved,
        variables: {
          resolved: 'N'
        },
        authMode: 'AWS_IAM'        
      })

      const its = result.data.messagesByResolved.items.map((item) => {
        return {
          ...item,
          checked: false
        }
      })
      setMessages(its)
    } catch (err) {
      console.error('Amplify listComms error...: ', err)
    }      
  }

  //Load unresolved messages on mount
  useEffect(() => {
    fetchUnresolvedMessages()
  }, [])

  //Subscribe to onCreateComm
  useEffect(() => {
    const subscription = API
      .graphql({
        query: onCreateComm,
        authMode: 'AWS_IAM'
      })
      .subscribe({
        next: (event) => {
          setMessages([...messages, event.value.data.onCreateComm])
        },
        error: (error) => {
          console.log('onCreateComm subscription error', error)
        }        
      })
  
    return () => {
      subscription.unsubscribe()
    }
  }, [messages])

  //Subscribe to onUpdateComm
  useEffect(() => {
    const subscription = API
      .graphql({
        query: onUpdateComm,
        authMode: 'AWS_IAM'
      })
      .subscribe({
        next: (event) => {
          fetchUnresolvedMessages()          
        },
        error: (error) => {
          console.log('onUpdateComm subscription error', error)
        }        
      })
  
    return () => {
      subscription.unsubscribe()
    }
  }, [messages])

  const items = messages.map((message, index) => {
    let item = {...message}
    item.handleChange = (event) => {
      let values = [...messages]
      values[index].checked = event.target.checked
      setMessages(values)              
    }
    return item
  })

  const handleReply = (e) => {
    setMessage(messages[e.currentTarget.value])
    setTriggerReply(!triggerReply)
  }

  const inquiryType = (isFeedback, forAppointment) => {
    if (isFeedback)
      return 'secondary'
    else if (forAppointment)
      return 'primary'
    else
      return 'default'
  }

  const markResolved = () => {
    try {
      items.forEach(item => {
        if (item.checked) {
          API.graphql({
            query: updateComm,
            variables: {
              input: {
                id: item.id,
                resolved: "Y"
              }
            },
            authMode: 'AWS_IAM'
          })
        }
      })
    } catch (err) {
      console.error('Amplify updateComms error...: ', err)
    }
  }

  return (
    <Content title='Message browser' tagline="Click a message to respond" titleImg={titleImg}>
      {items.map((item, index) => (
        <Grid container key={item.id} alignItems="center">
          <Grid item align="left" xs={2} md={1}>
            <Checkbox
              checked={item.checked}
              onChange={item.handleChange}
              color='primary'
            />
          </Grid>
          <Grid item align="left" xs={2} md={2}>
            <Button 
              color={inquiryType(item.isFeedback, item.forAppointment)}
              value={index}
              size="small"
              onClick={handleReply}
            >
              {item.name}
            </Button>
          </Grid>
          <Grid item align="left" xs={2} md={2}>
            {localDateTime(item.createdAt)}
          </Grid>
          <Grid item align="left" xs={3} md={2}>
            <a href={`tel:+${item.phone}`}>{item.phone}</a>
          </Grid>
          <Grid item align="left" xs={3} md={5}>
            {item.message}
          </Grid>
        </Grid>
      ))}
      <Button color='primary' onClick={markResolved}>
        Mark selected messages as resolved
      </Button>
      <ReplyForm 
        triggerOpen={triggerReply} 
        initOpen={false} 
        message={message}
      />
    </Content>
  )
}

export default MessageBrowser