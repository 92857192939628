import React, { useState, useEffect } from 'react'
import 'react-quill/dist/quill.snow.css'
import Button from '@material-ui/core/Button'
import Content from './content'
import { navigate } from 'gatsby'
import { isAdmin } from './auth/app-user'
import titleImg from '../data/general-information/bg6.jpg'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { fetchPatientInfo } from '../utils/booking-api'

const PatientUpdateRequest = () => {
  const [id, setId] = useState('')
  const [patientInfo, setPatientInfo] = useState(null)
  const name = patientInfo ? `${patientInfo.firstname} ${patientInfo.surname}` : ''
  const [mobilePhone, setMobilePhone] = useState('')
  const [requestType, setRequestType] = useState("general")
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (!isAdmin())
      navigate('/')
  }, [])

  useEffect(() => {
    if (patientInfo)
      setMobilePhone(patientInfo.mobilePhone)
  }, [patientInfo])

  const handleSend = async () => {
    try {
      let reply

      if (requestType === "general")
        reply = `${message} `
      else if (requestType === "emergency")
        reply = `Click https://register.aspiremedicalcentre.com.au/emergency?id=${id} to update your emergency & next of kin contact. `
      else if (requestType === "address")
        reply = `Click https://register.aspiremedicalcentre.com.au/address?id=${id} to update your address. `
      else
        reply = `Click https://register.aspiremedicalcentre.com.au/email?id=${id} to update your email. `

      const response = await fetch(`${process.env.GATSBY_ASPIRE_COMM_SERVER}/send-text`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          phoneNumber: mobilePhone,
          reply: reply
        })
      })
      const code = await response.json()
      if (code === 0) {
        alert((`Message was sent to ${mobilePhone} successfully`))
        setId('')
        setPatientInfo(null)
      } else {
        alert("SMS sending failed")  
      }
    } catch(err) {
      console.error('SMS sending error...: ', err)
      alert("SMS sending failed")
    }
  }

  return (
    <Content title='SMS Patients'  tagline="Inform & Update requests" titleImg={titleImg}>
      <FormControl component="fieldset">
        <RadioGroup 
          row
          aria-label="request type" 
          name="request type" 
          value={requestType} 
          onChange={(event) => setRequestType(event.target.value)}
        >
          <FormControlLabel value="general" control={<Radio />} label="Any text message" />
          <FormControlLabel value="emergency" control={<Radio />} label="Emergency & Next of kin update request" />
          <FormControlLabel value="address" control={<Radio />} label="Address update request" />
          <FormControlLabel value="email" control={<Radio />} label="Email update request" />
        </RadioGroup>
      </FormControl>
      {
        requestType != "general"
        &&
        <Grid container alignItems="center">
          <Grid item align="left" xs={6} md={4}>
            <TextField
              required
              margin="dense"
              label="patient id"
              type="tel"
              value={id}
              onChange={(event) => setId(event.target.value.trim())}
            />                                  
          </Grid>
          <Grid item align="left" xs={6} md={4}>
            <Button 
              className="mt-3"
              variant="contained" 
              onClick={() => fetchPatientInfo(parseInt(id), setPatientInfo)} 
              color="primary" 
              disabled={id.length === 0}
            >
              Get
            </Button>
          </Grid>
        </Grid>
      }
      {
        requestType == "general"
        &&
        <TextField
          id="message"
          required
          margin="dense"
          label="message"
          type="text"
          multiline
          rows="5"
          fullWidth
          variant="outlined"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      }
      <Grid container alignItems="center">
        <Grid item align="left" xs={12} md={4}>
          <TextField
            margin="dense"
            label="patient name"
            type="text"
            value={name}
          />                                  
        </Grid>
        <Grid item align="left" xs={12} md={4}>
          <TextField
            margin="dense"
            label="mobile number"
            type="tel"
            value={mobilePhone}
            onChange={(event) => setMobilePhone(event.target.value)}
          />                                  
        </Grid>
        <Grid item align="left" xs={12} md={4}>
          <Button 
            className="mt-3"
            variant="contained" 
            onClick={handleSend} 
            color="primary"
            disabled={mobilePhone.length < 10 || (requestType != "general" && id.length === 0 )}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </Content>
  )
}

export default PatientUpdateRequest