import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Content from './content'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import { navigate } from 'gatsby'
import titleImg from '../data/general-information/bg6.jpg'
import { isAdmin } from './auth/app-user'
import { API } from 'aws-amplify'
import { appointmentsByTime, listAppointments }  from '../graphql/queries'
import { localDateTime, localDateString } from '../utils/date-formatter'

const BookingBrowser = () => {
  const [apts, setApts] = useState([])
  const [aptDate, setAptDate] = useState(new Date())
  const [patient, setPatient] = useState("")
  const itemLimit = 1000

  useEffect(() => {
    if (!isAdmin())
      navigate('/')
  }, [])

  const fetchAppointments = async (date) => {
    try {
      let variables = {
        limit: itemLimit,
        appointmentDate: localDateString(date)
      }
      const result = await API.graphql({
        query: appointmentsByTime,
        variables: variables,
        authMode: 'AWS_IAM'        
      })

      setApts(result.data.appointmentsByTime.items)
    } catch (err) {
      console.error('Amplify appointmentsByTime error...: ', err)
    }      
  }

  useEffect(() => {
    fetchAppointments(aptDate)
  }, [])

  const changeDate = (date, offset) => {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + offset)
    return copy    
  }

  const fetchPrev = () => {
    const newDate = changeDate(aptDate, -1)
    setAptDate(newDate)
    fetchAppointments(newDate)
  }

  const fetchNext = () => {
    const newDate = changeDate(aptDate, 1)
    setAptDate(newDate)
    fetchAppointments(newDate)
  }

  const search = async() => {
    try {
      if (aptDate) {
        let variables = {
          limit: itemLimit,
          appointmentDate: localDateString(aptDate)
        }

        if (patient.length > 0) {
          variables.filter = {
            patientID: {contains: patient.toUpperCase()}
          }
        }

        const result = await API.graphql({
          query: appointmentsByTime,
          variables: variables,
          authMode: 'AWS_IAM'        
        })

        setApts(result.data.appointmentsByTime.items)
      } else {
        const variables = {
          limit: itemLimit,
          filter: {
            patientID: {contains: patient.toUpperCase()}
          }
        }
        const result = await API.graphql({
          query: listAppointments,
          variables: variables,
          authMode: 'AWS_IAM'        
        })

        setApts(result.data.listAppointments.items)
      }
    } catch (err) {
      console.error('Amplify appointmentsByTime error...: ', err)
    }      
  }

  return (
    <Content title='Booking browser' tagline="Examine online bookings" titleImg={titleImg}>
      <Grid container justify="space-between" alignItems='center' spacing={3} style={{marginBottom: 20}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item align="left" md={4}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="start-date-picker"
              label="Select appointment date"
              value={aptDate}
              onChange={(date) => setAptDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item align="left" md={4}>
          <TextField
            margin="dense"
            label="Patient name/DOB "
            type="text"
            fullWidth
            value={patient}
            onChange={(event) => setPatient(event.target.value)}
          />
        </Grid>
        <Grid item align="center" md={4}>
          <Button
            color="primary"
            variant="contained"
            onClick={search} 
            disabled={aptDate === null && patient.length === 0}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid container key="heading" alignItems="center">
        <Grid item align="left" lg={1}>
          BP ID
        </Grid>
        <Grid item align="left" lg={2}>
          Appointment Time
        </Grid>
        <Grid item align="left" lg={3}>
          Patient
        </Grid>
        <Grid item align="left" lg={1}>
          Booked On
        </Grid>
        <Grid item align="left" lg={2}>
          Booked By
        </Grid>
        <Grid item align="left" lg={1}>
          Canceled On
        </Grid>
        <Grid item align="left" lg={2}>
          Canceled By
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      {apts.map((apt) => (
        <Grid container key={apt.id} alignItems="center">
          <Grid item align="left" lg={1}>
            {apt.id}
          </Grid>
          <Grid item align="left" lg={2}>
            {localDateTime(apt.appointmentTime)}
          </Grid>
          <Grid item align="left" lg={3}>
            {apt.patientID}
          </Grid>
          <Grid item align="left" lg={1}>
            {localDateString(apt.createdAt)}
          </Grid>
          <Grid item align="left" lg={2}>
            {apt.bookedBy}
          </Grid>
          <Grid item align="left" lg={1}>
            {apt.canceledBy ? localDateString(apt.updatedAt) : null}
          </Grid>
          <Grid item align="left" lg={2}>
            {apt.canceledBy}
          </Grid>
        </Grid>
      ))}
      <Grid container alignItems="center">
        <Grid item align="left" lg={6}>
          <Button
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={fetchPrev}
            disabled={aptDate === null || patient.length > 0}
          >
            Prev
          </Button>
        </Grid>
        <Grid item align="right" lg={6}>
          <Button
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            onClick={fetchNext}
            disabled={aptDate === null || patient.length > 0}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Content>
  )
}

export default BookingBrowser