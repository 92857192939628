import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Content from './content'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { navigate } from 'gatsby'
import titleImg from '../data/general-information/bg6.jpg'
import { isAdmin } from './auth/app-user'
import { userIDs } from '../utils/bp-codes' 
import { getUserApts } from '../utils/booking-api'
import { localDateString } from '../utils/date-formatter'

const ProviderAppointments = () => {
  const [apts, setApts] = useState([])
  const [aptDate, setAptDate] = useState(new Date())
  const [userID, setUserID] = useState(0)

  useEffect(() => {
    if (!isAdmin())
      navigate('/')
  }, [])

  return (
    <Content title='Provider Appointments' tagline="My booked appointments on a day" titleImg={titleImg}>
      <Grid container justify="space-between" alignItems='center' spacing={3} style={{marginBottom: 20}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item align="left" md={4}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="start-date-picker"
              label="Select appointment date"
              value={aptDate}
              onChange={(date) => setAptDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item align="left" md={4}>
          <FormControl fullWidth >
              <InputLabel htmlFor="provider-native-simple">Provider*</InputLabel>
              <Select
                native
                value={userID}
                onChange={event => setUserID(parseInt(event.target.value))}
                inputProps={{
                  name: 'provider',
                  id: 'provider-native-simple',
                }}
              >
                {userIDs.map((item, index) => <option key={index} value={item.code}>{item.label}</option>)}              
              </Select>
            </FormControl>
        </Grid>
        <Grid item align="center" md={4}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => getUserApts(userID, localDateString(aptDate), setApts)} 
            disabled={userID === 0}
          >
            Get
          </Button>
        </Grid>
      </Grid>
      <Grid container key="heading" alignItems="center" spacing={2}>
        <Grid item align="right" lg={2} xs={4}>
          Appointment Time
        </Grid>
        <Grid item align="left" lg={10} xs={8}>
          Patient Name
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      {apts.map((apt) => (
        <Grid container key={apt.id} alignItems="center" spacing={2}>
          <Grid item align="right" lg={2} xs={4}>
            {apt.timeString}
          </Grid>
          <Grid item align="left" lg={10} xs={8}>
            {apt.patientName}
          </Grid>
        </Grid>
      ))}
    </Content>
  )
}

export default ProviderAppointments