import React, { useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getUser } from './auth/app-user'
import { API } from 'aws-amplify'
import { updateComm }  from '../graphql/mutations'
import { aspireMCBookingEmail } from '../utils/booking-api'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
    marginBottom: 20
  },
  close: {
    justifyContent: "flex-end"
  }  
}))

export default function ReplyForm({theme, triggerOpen, initOpen, message}) {
  const [open, setOpen] = useState(false)
  const didMountRef = useRef(false)
  const [reply, setReply] = useState('')
  const [preferredMethod, setPreferredMethod] = useState("call")

  const classes = useStyles(theme)

  useEffect(() => {
    if (didMountRef.current) {
      setOpen(true)
      setReply('')
    }
    else {
      didMountRef.current = true
      setOpen(initOpen)
    }
  }, [triggerOpen, initOpen])

  useEffect(() => {
    if (message)
      setPreferredMethod(message.preferredMethod)
  }, [message])

  const handleSend = async () => {

    try {
      API.graphql({
        query: updateComm,
        variables: {
          input: {
            id: message.id,
            reply: reply,
            resolved: "Y"
          }
        },
        authMode: 'AWS_IAM'
      })

      const greeting = `Hi ${message.name.split(' ')[0]}, `
      const signature = getUser().given_name
      let response
      if (preferredMethod === 'email') {
        response = await fetch(`${process.env.GATSBY_ASPIRE_COMM_SERVER}/send-email`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            emails: [message.email],
            body: `<p>${greeting}</p><p>${reply}</p><br><p>Kind regards,</p><p>${signature}, Aspire Medical Centre Eastwood</p><br>
            Please DO NOT reply to this email. If you have further questions, visit https://aspiremedicalcentre.com.au to contact us.`,
            subject: 'Your enquiry for Aspire Medicare Centre Eastwood',
            source: aspireMCBookingEmail   
          })
        })
      } else {
        response = await fetch(`${process.env.GATSBY_ASPIRE_COMM_SERVER}/send-text`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            phoneNumber: message.phone,
            reply: `${reply} ${signature}`
          })
        })
      }

      const code = await response.json()

      if (code === 0)
        alert("Reply was delivered successfully.")
      else
        alert("Reply delivery failed")
    } catch (err) {
      console.error('Amplify updateComms error...: ', err)
      alert("Message updating failed")
    }

    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onBackdropClick={() => setOpen(false)}>
        <IconButton edge="start" color="inherit" className={classes.close} onClick={() => setOpen(false)} aria-label="close">
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography variant='h6' align="left" gutterBottom>
            {`Reply to ${message?.name} by`}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup 
              row
              aria-label="preferred-method" 
              name="preferred-method" 
              value={preferredMethod} 
              onChange={(event) => setPreferredMethod(event.target.value)}
            >
              <FormControlLabel value="text" control={<Radio />} label="text" id="prefer-text"/>
              <FormControlLabel value="call" control={<Radio />} label="call back" id="prefer-call"/>
              {
                message?.email
                &&
                <FormControlLabel value="email" control={<Radio />} label="email" id="prefer-email"/>
              }
            </RadioGroup>
          </FormControl>
          <TextField
            margin="dense"
            label="message"
            type="text"
            multiline
            rows="5"
            fullWidth
            variant="outlined"
            defaultValue={message?.message}
          />                                  
          <TextField
            required
            margin="dense"
            label="reply"
            type="text"
            multiline
            rows="5"
            fullWidth
            variant="outlined"
            value={reply}
            onChange={(event) => setReply(event.target.value)}
          />                                  
        </DialogContent>
        <DialogActions className={classes.button}>
          <Button 
            variant="contained" 
            onClick={handleSend} 
            color="primary" 
            disabled={reply === ''}
            fullWidth
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}