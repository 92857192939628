/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateComm = /* GraphQL */ `
  subscription OnCreateComm {
    onCreateComm {
      id
      name
      phone
      email
      message
      preferredMethod
      isFeedback
      forAppointment
      reply
      resolved
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComm = /* GraphQL */ `
  subscription OnUpdateComm {
    onUpdateComm {
      id
      name
      phone
      email
      message
      preferredMethod
      isFeedback
      forAppointment
      reply
      resolved
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComm = /* GraphQL */ `
  subscription OnDeleteComm {
    onDeleteComm {
      id
      name
      phone
      email
      message
      preferredMethod
      isFeedback
      forAppointment
      reply
      resolved
      createdAt
      updatedAt
    }
  }
`;
