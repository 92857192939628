import React from "react"
import { Router } from "@reach/router"
import UpdateNotice from "../../components/update-notice"
import MessageBrowser from "../../components/message-browser"
import PrivateRoute from "../../components/private-route"
import BookingBrowser from "../../components/booking-browser"
import PatientUpdateRequest from "../../components/patupdate-request"
import ProviderAppointments from "../../components/provider-appointments"
import { isAdmin } from '../../components/auth/app-user'

const App = () => {
  return (
    <Router basepath="/app">
      <PrivateRoute path="/update-notice" allowed={isAdmin()} component={UpdateNotice} />
      <PrivateRoute path="/message-centre" allowed={isAdmin()} component={MessageBrowser} />
      <PrivateRoute path="/booking-browser" allowed={isAdmin()} component={BookingBrowser} />
      <PrivateRoute path="/update-request" allowed={isAdmin()} component={PatientUpdateRequest} />
      <PrivateRoute path="/provider-appointments" allowed={isAdmin()} component={ProviderAppointments} />
    </Router>
  )
}
export default App